import './App.css';
import React, {useContext, useEffect, useState} from "react";
import {useReadContract, useWalletClient} from "wagmi";
import {nftContract} from "./models/abi";
import {Address} from "./models/types";
import {ToastContainer, toast} from "react-toastify";
import ErrorModal from "./Error";
import Poll from './Poll/Poll'

import {ConnectKitButton} from "connectkit";
import {UserContext} from "./UserContext";
import {useSignMessage} from "wagmi";
import { showThrottleMessage } from 'ethers';

type Props ={
  name: string | null;
}
function App({name} : Props) {
  const [lastToast, setLastToast] = useState(null as string | null);
  const [lastToastString, setLastToastString] = useState(null as string | null);
  const [isConnected, setIsConnected] = useState(false);
  const [address, setAddress] = useState<string | undefined>(undefined);
  const {ctxAddress, ctxSetAddress, ctxIsLoading, ctxSetIsLoading, ctxToken, ctxSetToken, ctxMessage, 
        ctxSignedMessage, ctxSetSignedMessage, ctxError, ctxSetError, ctxLastError, ctxSetLastError, 
        ctxIsParamCorrect, ctxRedirectUrl, ctxSetSubmitRefetch, ctxAuthUid,
        ctxSetDeadEnd, ctxDeadEnd } = useContext(UserContext);
  const {data: walletClient} = useWalletClient();

  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
  
  const isMobile = width <= 768;
  toast.bind(<ToastContainer
    position="top-right"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    limit={1}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="colored"
  />)
  const responseDataNft = useReadContract({
    ...nftContract,
    functionName: "getNFTInfo",
    args: [ctxAddress || "0x0000000000000000000000000000000000000000"],
    query: {},
  });

  if (
    ctxAddress &&
    responseDataNft.data !== null &&
    responseDataNft.data !== undefined &&
    ((responseDataNft.data[0] > 0 && ctxToken !== responseDataNft.data[1]) || (responseDataNft.data[1] <= 0 && ctxToken !== BigInt(-1)))
  ) {
    if (responseDataNft.data[1] <= 0) {
      ctxSetToken(BigInt(-1));
      ctxSetError('Token not found. Click the button below to logout');
      ctxSetDeadEnd('Click the button below to logout');
    } else {
      ctxSetToken(responseDataNft.data[0]);
    }
  }
  
  if (
    ctxAddress !== walletClient?.account.address.toLowerCase()
  ) {
    ctxSetAddress(walletClient?.account.address.toLowerCase() as Address | null);
  }

  useEffect(() => {
    ctxSetAddress(address ? `0x${address}` : null);
    if (isConnected &&  walletClient?.account.address &&
      ctxAddress !== walletClient?.account.address) {
    }
  }, [isConnected]);
  const { signMessage, isSuccess, isError, isPending, data:signedMessage, reset } = useSignMessage();

  if (ctxAddress != null && !isSuccess && !isError && !isPending && ctxToken != null && ctxMessage != null && ctxSignedMessage == null && ctxIsParamCorrect ==null) {
    signMessage({ message: { raw: ctxMessage as `0x${string}`}, account: ctxAddress});
    if (!ctxIsLoading){
      ctxSetIsLoading(true);
    }
  }
  if (isSuccess && !!signedMessage && ctxSignedMessage != signedMessage){
    ctxSetSignedMessage(signedMessage);
    ctxSetIsLoading(true);
    if (!ctxIsLoading){
      ctxSetIsLoading(true);
    }
  }
  if (isError && ctxError != "Signing Request Failed" && (ctxLastError != ctxError || ctxError == null ) ) {
    ctxSetError("Signing Request Failed");
      if( ctxIsLoading){
        ctxSetIsLoading(false);
      }
    }
  useEffect(() => {
    if(ctxMessage != null && ctxSignedMessage == null && !ctxIsLoading){
      ctxSetIsLoading(true);
      reset();

    }
  }, [ctxMessage])

  const handleClick = ({ show, isConnected, address }: { show: () => void; isConnected: boolean; address: string | undefined }) => {
    if (isMobile){
      if(!window.location.href.match('Redirected=true')){
        window.location.href = 'https://metamask.app.link/dapp/' + window.location.href.split('://')[1] + '&Redirected=true';
        return
      }
    }
    if (ctxAddress !== `${walletClient?.account.address.toLowerCase()}` || ctxAddress == null || !isConnected ){
      ctxSetAddress(address ? `0x${walletClient?.account.address}` : null);
      ctxSetIsLoading(true);
      show();
    } else {
      reset();
      ctxSetError(null);
      signMessage({ message: { raw: ctxMessage as `0x${string}`}, account: ctxAddress});
      ctxSetIsLoading(true);
    }
  };

  if (ctxError) {
    var id = lastToast;
    if (ctxError == "Signing Request Failed" && lastToastString != "Signing Request Failed"){
      id = (toast.error("Signing Request Failed", {toastId: "Signing Request Failed"})).toString();
      setLastToast(id);
      setLastToastString("Signing Request Failed");
    } else if (ctxError == "Message Request Failed" && ctxError != lastToastString && lastToastString != "Message Request Failed"){
      id = (toast.error("Message Request Failed", {toastId: "Message Request Failed"})).toString();
      setLastToast(id);
      setLastToastString("Message Request Failed");
    } else if (ctxError == "Authentication Request Failed" && ctxError != lastToastString && lastToastString != "Authentication Request Failed"){
      id = (toast.error("Authentication Request Failed", {toastId: "Authentication Request Failed"})).toString();
      setLastToast(id);
      setLastToastString("Authentication Request Failed");
    }else if (ctxError == "Url not found" && lastToastString != "Url not found"){
      id = (toast.error("Url not found", {toastId: "Url not found"})).toString();
      setLastToast(id);
      setLastToastString("Url not found");
    } else if (ctxError == "Submit Request Failed" && lastToastString != "Submit Request Failed"){
      id = (toast.error("Submit Request Failed", {toastId: "Submit Request Failed"})).toString();
      setLastToast(id);
      setLastToastString("Submit Request Failed");
    } else if (ctxError == "Api Error" && lastToastString != "Unknown Server Error"){
      id = (toast.error("Unknown Server Error", {toastId: "Unknown Server Error"})).toString();
      setLastToast(id);
      setLastToastString("Unknown Server Error");
    }
  }
  return (
    <>
      <div className='app-body'>
        <div className="effect">
          <img src="/assets/effect.png" alt="vector" />
        </div>

        {ctxIsLoading?<div className="logoIntro">
          <img src="/assets/logoDentroNew.png" alt="logo" />
          <img src="/assets/logoForaNew.png" alt="logo" />
        </div>:<></>}
        {!ctxIsLoading?
        <main>
          <div className="logo">
            <img src="/assets/login.png" alt="login" />
          </div>
          
          <div className="textMain"><h1>Join the<br></br>
            Livestream here.</h1>
          <p>Be part of the largest community of market makers.</p>
          </div>
            
          <div className='inputContainer'>
            {
              (ctxDeadEnd !== null?
                <button className="btnConnectError" id="disabled" disabled>
                    {ctxDeadEnd}
                </button>
                :
                (ctxAddress == null ?<ConnectKitButton.Custom>
                  {({ show, isConnected, address }) => (
                    <button className="btnConnect" id="show-toast-success" onClick={() => handleClick({ show: show || (() => {}), isConnected, address })}  >Connect
                      Wallet
                  </button>)}
                </ConnectKitButton.Custom>:
                <ConnectKitButton.Custom>
                  {({ show, isConnected, address }) => (
                    <button className="btnConnect" id="show-toast-success" onClick={() => handleClick({ show: show || (() => {}), isConnected, address })}  >
                      Authenticate
                  </button>)}
                </ConnectKitButton.Custom>)
              )
            }
            {ctxAddress !== null? 
            
              <div
              className="connect-button"
              style={{display: `flex`, marginTop:'8px',justifyContent:'center'}}
            >
                <ConnectKitButton.Custom>
                  {({ show, isConnected, address }) => {
                    if (isConnected){
                      return (
                      <div className='btnConnectWarning' onClick={ (e)=>{
                          reset();
                          show();
                        }}>Logout</div>)
                    }
                    return (<></>)
                  }}
                </ConnectKitButton.Custom>
            </div>
            : <></>}
          
          </div>
          <footer className="footer">
            <img src="/assets/logoMega.png" alt="MEGA MAKER"/>
          </footer>
        </main>:<></>}
      </div>
        { ctxError != null ? <ErrorModal lastErrorMessage={lastToastString ?? ""} errorMessage={ctxError ?? ""} /> : <></>}
      </>
  );
}

export default App;
