import React, {useContext, useState, useEffect, ReactNode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {UserToken, Address, SurveyState } from './models/types';
import App from './App';
import {UserContext, ContextData} from './UserContext';
import { Web3Provider } from './Web3Provider';
import useAxios from 'axios-hooks'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from 'react-router-dom';
import { isFloat32Array } from 'util/types';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

interface Props {
  children?: ReactNode;
}



const UserProvider = ({children, ...props}: Props) => {
  const [pathUrl, setPathUrl] = useState(null as string | null); 
  const [isParamCorrect, setIsParamCorrect] = useState(null as boolean | null); 
  const [loading, setLoading] = useState(true); 
  const [myToken, setMyToken] = useState(null as UserToken);
  const [myAddress, setMyAddress] = useState(null as Address);
  const [myMessage, setMyMessage] = useState(null as string | null);
  const [mySignedMessage, setMySignedMessage] = useState(null as string | null);
  const [myAuthUid, setMyAuthUid] = useState(null as string | null);
  const [myError, setMyError] = useState(null as string | null);
  const [lastError, setLastError] = useState(null as string | null);
  const [mySuccess, setMySuccess] = useState(null as string | null);
  const [deadEnd, setDeadEnd] = useState(null as string | null); 
  const contextValues = {} as ContextData;
  contextValues.ctxToken = myToken;
  contextValues.ctxAddress = myAddress;
  contextValues.ctxError = myError;
  contextValues.ctxLastError = lastError;
  contextValues.ctxSuccess = mySuccess;
  contextValues.ctxIsLoading = loading;
  contextValues.ctxMessage = myMessage;
  contextValues.ctxSignedMessage = mySignedMessage;
  contextValues.ctxAuthUid = myAuthUid;
  contextValues.ctxIsParamCorrect = isParamCorrect;
  contextValues.ctxDeadEnd = deadEnd;
  contextValues.ctxSetAddress = setMyAddress;
  contextValues.ctxSetToken = setMyToken;
  contextValues.ctxSetError = setMyError;
  contextValues.ctxSetLastError = setLastError;
  contextValues.ctxSetSuccess = setMySuccess;
  contextValues.ctxSetIsLoading = setLoading;
  contextValues.ctxSetMessage = setMyMessage;
  contextValues.ctxSetSignedMessage = setMySignedMessage;
  contextValues.ctxSetAuthUid = setMyAuthUid;
  contextValues.ctxSetIsParamCorrect = setIsParamCorrect;
  contextValues.ctxSetDeadEnd = setDeadEnd;
  const location = useLocation();
  
  if (pathUrl == null && isParamCorrect == null){
    var splittedParams = location.search.split('&');
    if (pathUrl == null && location.search.length > 0 && splittedParams.length > 1){
      setPathUrl(splittedParams[0].replace('?DestinationUrl=',''))
      setMyAuthUid(splittedParams[1].replace('AuthUid=',''))
      setIsParamCorrect(true);
    } else {
      setIsParamCorrect(false);
      setMyError("Url not found");
      setDeadEnd('Url not found');
    }
  }
  const [{ data:generatedData, error: generatedError  }, refetchGenerateMessage, resetMessage] = useAxios({
    url: '/Api/GenerateMessage',
    method: 'post',
    data: {
      currentAddress: myAddress,
      ownerOf: Number(myToken)
    },
  },
  { manual: true }
);

  if (!!generatedData && !generatedError && myAddress != null && myToken != null && generatedData.message != myMessage) {
    setMyMessage(generatedData.message);
  }
  if (!generatedData && !!generatedError && myError != "Message Request Failed" && myAddress != null && myToken != null  && lastError != "Message Request Failed" && myError != "Message Request Failed") {
    setMyError("Message Request Failed");
  }
  const [{ data:authData, error: authError, loading: authLoading }, refetchAuth, resetAuth] = useAxios({
    url: '/Api/Authenticate',
    method: 'post',
    data: {
      currentAddress: myAddress,
      ownerOf: Number(myToken),
      signedMessage: mySignedMessage,
      destinationUrl: pathUrl,
      authUid: myAuthUid
    }
  },
  { manual: true }
  );

  if (!!authData && !authError && mySuccess != authData.token && !authLoading && myAddress != null && myToken != null && mySignedMessage != null) {
      window.location.href = pathUrl.replaceAll('%2f', '/').replaceAll('%3a', ':') + '?verified=' + authData.token;
  }
  if (!authData && !!authError && !authLoading && myError != "Authentication Request Failed" && myAddress != null && myToken != null && mySignedMessage != null && lastError != myError) {
    setMyError("Authentication Request Failed");
    if( loading){
      setLoading(false);
    }
  }
  useEffect(() => {
    if (myAddress != null && myToken != null && myMessage == null) {
      try {
        refetchGenerateMessage().catch(e => {}
        );
      } catch (err) {
      }
    }
    if (myAddress != null  && myToken != null && myMessage != null && mySignedMessage != null && mySuccess== null &&!authLoading && !authData) {
      try {
        refetchAuth().catch(e => {}
        );
      } catch (err) {
      }
      return
    }
    if (mySuccess != null) {
      if (mySuccess && mySuccess.length > 30) {
      } else if  (mySuccess && mySuccess == "Invalid Uid")  {
      }else if  (mySuccess && mySuccess == "Invalid Signature")  {
      }
      return 
    }
  }, [myToken, myAddress, myError, mySuccess, myMessage, mySignedMessage ]);

  useEffect(() => {
    if(loading){
      const timer = setTimeout(() => {
        if(loading){
          setLoading(false);
        }
      }, 2000);
  
      return () => clearTimeout(timer);
    }
  }, [loading]);

  useEffect(() => {
    if(myAddress == null) {
      resetMessage();
      resetAuth();
      setMyError(null);
      setLastError(null);
      setMySuccess(null);
      setDeadEnd(null);
      setMyToken(null);
      setMyMessage(null);
      setMySignedMessage(null);
      setIsParamCorrect(null);
      setPathUrl(null);
    }
  }, [myAddress])

  return (
    <UserContext.Provider value={contextValues}>
      {children}
    </UserContext.Provider>
  );
};

const AppWrapper = () => {
  const {ctxAddress } = useContext(UserContext);
  return (
    <App name={ctxAddress?.toString() ?? "none"}/>
  )
}

const router = createBrowserRouter([
  {
    path: "/*",
    element: <>
    <UserProvider>
        <Web3Provider>
          <AppWrapper />
        </Web3Provider>
      </UserProvider>
    </>,
  },
]);

root.render(

        <RouterProvider router={router} />
  );

