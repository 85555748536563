import {createContext} from 'react'
import {UserCoin, UserToken, Address, Offer, OfferTx, SurveyState} from './models/types'
import { RefetchFunction } from 'axios-hooks'

export const UserContext = createContext({
  ctxToken: null as UserToken,
  ctxAddress: null as Address,
  ctxError: null as string | null,
  ctxLastError: null as string | null,
  ctxSuccess: null as string | null,
  ctxMessage: null as string | null,
  ctxSignedMessage: null as string | null,
  ctxIsParamCorrect: null as boolean | null,
  ctxIsLoading: true as boolean,
  ctxAuthUid: null as string | null,
  ctxRedirectUrl: null as string | null,
  ctxDeadEnd: null as string | null,
  ctxSetToken: ()=>null,
  ctxSetAddress: ()=>null,
  ctxSetError: () => null,
  ctxSetLastError: () => null,
  ctxSetMessage: () => null,
  ctxSetSignedMessage: () => null,
  ctxSetSuccess:() => null,
  ctxSetIsParamCorrect:() => null,
  ctxSetIsLoading:() => null,
  ctxSetAuthUid: () => null,
  ctxSetRedirectUrl: () => null,
  ctxSetSubmitRefetch: () => null,
  ctxSetDeadEnd: () => null,
} as ContextData)

export type ContextData = {
  ctxToken: UserToken,
  ctxAddress: Address,
  ctxError: null | string,
  ctxLastError: null | string,
  ctxSuccess: null | string,
  ctxMessage: null | string,
  ctxSignedMessage: null | string,
  ctxIsParamCorrect: boolean | null,
  ctxIsLoading: boolean,
  ctxAuthUid: null | string,
  ctxRedirectUrl: null | string,
  ctxDeadEnd: string | null,
  ctxSetToken: React.Dispatch<React.SetStateAction<bigint | null>>,
  ctxSetAddress: React.Dispatch<React.SetStateAction<`0x${string}` | null>>,
  ctxSetError: React.Dispatch<React.SetStateAction<string | null>>,
  ctxSetLastError: React.Dispatch<React.SetStateAction<string | null>>,
  ctxSetSuccess: React.Dispatch<React.SetStateAction<string | null>>,
  ctxSetMessage: React.Dispatch<React.SetStateAction<string | null>>,
  ctxSetSignedMessage: React.Dispatch<React.SetStateAction<string | null>>,
  ctxSetIsParamCorrect:React.Dispatch<React.SetStateAction<boolean | null>>,
  ctxSetIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  ctxSetAuthUid: React.Dispatch<React.SetStateAction<string | null>>,
  ctxSetRedirectUrl: React.Dispatch<React.SetStateAction<string | null>>,
  ctxSetSubmitRefetch: RefetchFunction<any, any>,
  ctxSetDeadEnd: React.Dispatch<React.SetStateAction<string | null>>,
}