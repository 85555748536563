import { createConfig, http, CreateConfigParameters, Config } from "wagmi";
import { getDefaultConfig } from "connectkit";
import { polygon } from "wagmi/chains"
import { injected, walletConnect, metaMask  } from 'wagmi/connectors'

const configExecutor: (parameters: CreateConfigParameters) => Config  = createConfig;

export const config = configExecutor(
  getDefaultConfig({
    // chains: [testNet],
    // transports: {
    //   [testNet.id]: http()
    // },
    chains: [polygon],
    transports: {
      [polygon.id]: http(`https://polygon-mainnet.g.alchemy.com/v2/QETWM1FfuLaQ2yEVKhSKAB9AkfsBq4-4`),
    },
    connectors: [metaMask(), injected(),walletConnect({projectId: "f1011529a650403b9293876045da1a41"}) ],

    walletConnectProjectId: "f1011529a650403b9293876045da1a41",

    appName: "FTKXMarket",

    appDescription: "",
    appUrl: "https://127.0.0.1:3000", // your app's url
    appIcon: "https://family.co/logo.png",
  }),
);